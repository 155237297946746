import { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { getAssetUrl, doScrollToTop } from "../../../../helpers/general";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { Link } from "react-router-dom";
import { getClassByFormat, MatchFormatShortNames } from "../../Home/constants";
import { getRightOrder } from "../../Home/common/liveMatchesCarousel/helper";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { getMatchOversText } from "../../../../helpers/matchOvers";

const nextIcon = getAssetUrl("next-svg.svg");
const prevIcon = getAssetUrl("prev-svg.svg");
const upArrow = getAssetUrl("upArrow.svg");
const downArrow = getAssetUrl("downArrow.svg");

const MatchResult = {
  "*": "bg-[#BDBDBD] md:text-2xl md:pt-3 pt-[4px] text-[14px]",
  "W": "bg-[#4AA450] md:text-xs text-[8px]",
  "L": "bg-[#E06C6C] md:text-xs text-[8px]",
};

const ArrowButton = ({ direction, onClick, icon, showArrow }) => (
  <button
    className={`absolute ${direction === "left" ? "left-2 translate-y-1/2" : "right-2 translate-y-1/2"} live-nav hidden md:block`}
    onClick={onClick}
    style={{
      visibility: showArrow ? "visible" : "hidden",
    }}
  >
    <ShimmerEffect src={icon} alt={direction} className="w-16" />
  </button>
);

export const MatchesCarousel = ({ data, pageName = "" }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const cardRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const teamAContainerRef = useRef(null);
  const teamBContainerRef = useRef(null);
  const [teamAShowLeftArrow, setTeamAShowLeftArrow] = useState(false);
  const [teamAShowRightArrow, setTeamAShowRightArrow] = useState(true);
  const [teamBShowLeftArrow, setTeamBShowLeftArrow] = useState(false);
  const [teamBShowRightArrow, setTeamBShowRightArrow] = useState(true);

  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenWidth(window.innerWidth);
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth);
      }
    }, 200);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [cardRef.current]);

  const firstMatchTeama = pageName === "matchInfo" ? data?.[0] : data?.teams[0] || {};
  const firstMatchTeamb = pageName === "matchInfo" ? data?.[1] : data?.teams[1] || {};
  if (!firstMatchTeama || !firstMatchTeamb) {
    return null;
  }
  return (
    <div className="relative md:bg-[#FFFFFF] bg-[#F9FBFF] rounded-b-2xl md:rounded-2xl overflow-hidden live-match-carousel">
      <TeamsWiseCrousal firstMatch={firstMatchTeama} teamName="teama" isLeftArrowShow={teamAShowLeftArrow} isRightArrowShow={teamAShowRightArrow} setLeftArrowShow={setTeamAShowLeftArrow} setRightArrowShow={setTeamAShowRightArrow} containerRef={teamAContainerRef} />
      <TeamsWiseCrousal firstMatch={firstMatchTeamb} teamName="teamb" isLeftArrowShow={teamBShowLeftArrow} isRightArrowShow={teamBShowRightArrow} setLeftArrowShow={setTeamBShowLeftArrow} setRightArrowShow={setTeamBShowRightArrow} containerRef={teamBContainerRef} />
    </div>
  );
};

const TeamsWiseCrousal = ({ firstMatch, teamName, containerRef, setLeftArrowShow, setRightArrowShow, isLeftArrowShow, isRightArrowShow,}) => {
  const [openCarousel, setOpenCarousel] = useState(teamName);
  const nextSlide = (containerRef, setShowLeftArrow, setShowRightArrow) => {
    if (containerRef.current) {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft + 285,
        behavior: "smooth",
      });
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
      setShowRightArrow(containerRef.current.scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth);
    }
  };

  const prevSlide = (containerRef, setShowLeftArrow, setShowRightArrow) => {
    if (containerRef.current) {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft - 285,
        behavior: "smooth",
      });
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
      setShowRightArrow(containerRef.current.scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth);
    }
  };
  const filteredTeamData = firstMatch?.lastFiveMatches?.map(match => ({
    ...match,
    team: match[teamName],
  }));
  const handleScroll = (containerRef, setShowLeftArrow, setShowRightArrow) => {
    if (containerRef.current) {
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
      setShowRightArrow(containerRef.current.scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth);
    }
  };
  const toggleTeamCarouselVisibility = () => {
    setOpenCarousel(openCarousel === teamName ? null : teamName);
  };
  const cardStyle = {
    scrollSnapAlign: "start",
  };
  return (
    <>
      <div className="border-t">
        <div className={`flex items-center justify-between bg-[#E8F1FF] md:bg-[#FFFFFF] px-4 py-2 ${teamName === "teamb" && openCarousel !== "teamb" ? "rounded-b-2xl md:rounded-none" : ""}`}>
          <div className="flex items-center md:gap-2 gap-1">
            {firstMatch.logo && (
              <img src={firstMatch.logo} alt={`${firstMatch.short_name} Logo`} className="md:w-[48px] md:h-[48px] w-6 h-6" />
            )}
            <span className="md:text-[16px] text-[10px] md:opacity-100 opacity-50 font-semibold">{firstMatch.short_name}</span>
          </div>
          <div className="flex items-center md:gap-6 gap-2">
            {firstMatch?.lastFive?.map((result, index) => (
              <div key={index}>
                <div className={`flex justify-center items-center text-[#FFFFFF] rounded-full md:w-8 md:h-8 w-[22px] h-[22px] ${MatchResult[result]}`}>
                  <p className="font-bold">{result}</p>
                </div>
              </div>
            ))}
            <div>
              {openCarousel === teamName ? (
                <>
                  <span className="md:hidden block">
                    <IoChevronUpSharp onClick={toggleTeamCarouselVisibility} />
                  </span>
                  <span className="hidden md:block">
                    <img src={upArrow} alt={`Hide ${teamName} Carousel`} onClick={toggleTeamCarouselVisibility} className="md:w-6 w-4 h-4 md:h-6" />
                  </span>
                </>
              ) : (
                <>
                  <span className="md:hidden block">
                    <IoChevronDownSharp onClick={toggleTeamCarouselVisibility} />
                  </span>
                  <span className="hidden md:block">
                    <img src={downArrow} alt={`Show ${teamName} Carousel`} onClick={toggleTeamCarouselVisibility} className="md:w-6 w-4 h-4 md:h-6" />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`content-container ${openCarousel ? 'content-container-open' : 'content-container-closed'} transition-max-height`}
        >
        {openCarousel === teamName && (
          <div
            ref={containerRef}
            className="flex overflow-x-auto hideScrollbar overflow-y-hidden live-match-list md:pb-6"
            onScroll={() => handleScroll(containerRef, setLeftArrowShow, setRightArrowShow)}
          >
            <div className="gap-4 flex md:mx-4 mx-3">
              {filteredTeamData.map((match, index) => (
                <div
                  key={index}
                  style={cardStyle}
                  className="flex-none w-[240px] md:w-[290px]"
                  data-matchid={match.match_id}
                >
                  <CompletedMatch match={match} teamId={firstMatch?.team_id} />
                </div>
              ))}
            </div>
            <ArrowButton direction="right" onClick={() => nextSlide(containerRef, setLeftArrowShow, setRightArrowShow)} icon={nextIcon} showArrow={isRightArrowShow} />
            <ArrowButton direction="left" onClick={() => prevSlide(containerRef, setLeftArrowShow, setRightArrowShow)} icon={prevIcon} showArrow={isLeftArrowShow} />
          </div>
        )}
      </div>
    </>
  )
}

const CompletedMatch = ({ match, teamId }) => {
  const { teama, teamb, subtitle, format, tournament_title, status_note, isShimmer } = match;
  const classname = isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
  return (
    <div className="bg-white rounded-2xl border border-[#E3E3E4] px-2 md:my-0 my-4 relative">
      <Link to={`/series/${match.tournament_slug}/schedule/${match.slug}/scorecard`} onClick={doScrollToTop}>
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className="text-[10px] text-gray-600 flex-grow live-match font-medium pl-1">
            {subtitle} • {" "}
            <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{tournament_title}</span>
          </p>
          <p className={`rounded-[40px] max-w-[150px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(format)}`}>
            {MatchFormatShortNames[format] || format}
          </p>
        </div>
        <div className="md:my-2">
          {getRightOrder(teama, teamb).map((team, i) => (
            <div data-teamid={team.team_id} key={i} className="flex items-center pb-1 pt-0.5">
              <div className="flex items-center w-[120px]">
                <div className="p-[2px] mr-0.5">
                  {!isShimmer && (
                  <ShimmerEffect
                    src={team?.logo}
                    alt={team?.name}
                    className="h-5 w-5 my-auto"
                  />
                  )}
                </div>
                <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] mt-1 ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                  {team?.short_name}
                </p>
              </div>
              <div className={`${classname} min-h-[16px] min-w-[83px] flex items-center whitespace-nowrap`}>
                <span className="text-[14px] leading-4 font-semibold ml-4">
                  {team?.scores?.replace(/\/10/g, '')}
                </span>
                {team.scores && (
                  <span className="text-[10px] text-[#808080] ml-1">
                    {getMatchOversText(match, team?.team_id)}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="absolute bottom-2 right-2">
          <div className={`flex justify-center items-center text-[#FFFFFF] font-semibold rounded-full md:w-8 md:h-[30px] md:p-2 p-1 w-[22px] h-[22px] ${match?.winning_team_id !== "0" ? match?.winning_team_id + "" === teamId + "" ? "bg-[#4AA450]" : "bg-[#E06C6C]" : "bg-[#BDBDBD]"} `}>
            <p
              className={
                match?.winning_team_id !== "0"
                  ? match?.winning_team_id + "" === teamId + "" ? "md:text-xs text-[8px]" : "md:text-xs text-[8px]"
                  : "md:text-2xl text-base mt-2"
              }
            >
              {match?.winning_team_id !== "0" ? (match?.winning_team_id + "" === teamId + "" ? "W" : "L") : "*"}
            </p>
          </div>
        </div>
        <div className="py-2">
          <div className={`${classname} min-h-[15px] md:w-[230px] w-[190px] pl-1 live-match text-[10px] font-semibold overflow-hidden`}
                    dangerouslySetInnerHTML={{ __html: status_note }}
          >
          </div>
        </div>
      </Link >
    </div >
  );
};